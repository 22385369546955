$(function() {
    "use strict";
    $('#stars li').on('mouseover', function(){
        var onStar = parseInt($(this).data('value'), 10); // The star currently mouse on

        // Now highlight all the stars that's not after the current hovered star
        $(this).parent().children('li.star').each(function(e){
            if (e < onStar) {
                $(this).addClass('hover');
            }
            else {
                $(this).removeClass('hover');
            }
        });

    }).on('mouseout', function(){
        $(this).parent().children('li.star').each(function(e){
            $(this).removeClass('hover');
        });
    });


    /* 2. Action to perform on click */
    $('#stars li').on('click', function(){
        var onStar = parseInt($(this).data('value'), 10); // The star currently selected
        var stars = $(this).parent().children('li.star');

        for (let i = 0; i < stars.length; i++) {
            $(stars[i]).removeClass('selected');
        }

        for (let i = 0; i < onStar; i++) {
            $(stars[i]).addClass('selected');
        }

        var ratingValue = parseInt($('#stars li.selected').last().data('value'), 10);
        $('input[name="rgcl"]').val(ratingValue);

    });
    function showErrors(data){
        $.each(data, function (key, value) {
            let index = -1;
            var input = null;
            if(key === 'image[]'){
                input = $('.ajax-form input[name="' + key + '"]');
                $(input).parent().addClass('is-invalid');
                $(input).parent().parent().append('<small class="help-block invalid-feedback">'+value+'</small>');
            }else{
                if(key.indexOf(".") >= 0){
                    let keys = key.split(".");
                    key = keys[0]+"[]";
                    index = keys[1];
                }
                input = $('.ajax-form input[name="' + key + '"]');
                if(input.length === 0) {
                    input = $('.ajax-form select[name="' + key + '"]');
                    if(input.length === 0) {
                        if($('.ajax-form select[name="' + key + '[]"]').length > 0){
                            input = $('.ajax-form select[name="' + key + '[]"]');
                        }
                    }
                    if(input.length > 1) {
                        input = $(input)[index];
                    }
                }else{
                    if(input.length > 1) {
                        input = $(input)[index];
                    }
                }
                if(input.length === 0)
                    input = $('.ajax-form textarea[name="' + key + '"]');
                if($('.ajax-form input[name="' + key + '"]').attr('type') === "radio")
                    input = $('.ajax-form input[name="' + key + '"]').parent().parent();
                if(key === "image"){
                    input = $('.ajax-form input[name="' + key + '[]"]').parent();
                }
                if($('.ajax-form input[name="' + key + '"]').attr('type') === "file")
                    input = $('.ajax-form input[name="' + key + '"]').parent().parent();


                if(key.startsWith('image[')){
                    var test = key.match(/\[(.*)\]/);
                    if(test.length === 2){
                        let index = parseInt(test[1]);
                        $($('.event-gallery').find('.v-image')[index]).text(value);
                    }
                }else{
                    key = key.replace("[]", "").replace(".", "-").replace("[", "-").replace("]", "");
                    $(input).parent().append('<small class="help-block invalid-feedback">'+value+'</small>');

                }
                $(input).addClass('is-invalid');
            }

        });
    }
    $(document).on('submit', '.ajax-form', function(e) {
        e.preventDefault();
        var formData = new FormData(this);

        let button = null;
        if($(this).find('button').length > 0)
            button = $(this).find('button')[0];
        else
            button = $('.ajax-form .log-submit-btn span');
        let buttonValue = $(button).text();
        $(button).text(lang.loading);
        $(button).prop('disabled', true);
        $('.ajax-form .invalid-feedback').remove();
        $('.ajax-form .alert').hide();
        $('.validation').text('');
        $('.is-invalid').removeClass('is-invalid');
        let callback = $(this).data('ajax-callback');
        let alert = $(this).data('alert');
        $.ajax({
            method: $(this).attr('method'),
            url: $(this).attr('action'),
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            dataType: "json"
        })
            .done(function(response) {
                $(button).prop('disabled', false);
                $(button).text(buttonValue);
                if(!alert || alert !== "disabled")
                    swal({
                        title: lang.successful,
                        text: (response.message)?response.message:response.data,
                        button: lang.close,
                        icon: "success", //built in icons: success, warning, error, info
                        timer: 3000,
                    });
                if(callback){
                    var c = eval(callback)
                    if (typeof c == 'function') {
                        c(true, response)
                    }
                }
            })
            .fail(function(data) {
                let title = lang.error;
                let icon = "error";
                if(data.responseJSON.hasOwnProperty('errors')){
                    title = lang.warning;
                    icon = "warning";
                }
                let message = data.responseJSON.message;
                if(data.responseJSON.message === 'The given data was invalid.'){
                    message = lang.formFailedError;
                }
                swal({
                    title: title,
                    text: (data.responseJSON.message)?message:data.responseJSON.data,
                    button: lang.close,
                    icon: icon, //built in icons: success, warning, error, info
                    timer: 3000,
                }).then(function (result) {
                    if(data.responseJSON.hasOwnProperty('errors')){
                        showErrors(data.responseJSON.errors);
                    }
                    $(button).prop('disabled', false);
                    $(button).text(buttonValue);
                    if(callback){
                        var c = eval(callback)
                        if (typeof c == 'function') {
                            c(false, data)
                        }
                    }

                });

            });
    });
    $(document).on('click', '.rooms-item', function (e){
        if($(this).data('href'))
            window.location.href = $(this).data('href');
    });
    $(document).on('change', '.ajax-request', function(e){
        if(e.target.type === "select-one")
            ajaxRequest($(this));
    });
    $(document).on('click', '.ajax-request', function(e){
        if(!$(this).data('confirm-text')) return createAjaxRequest($(this));
        let self = $(this);
        swal({
            title: $(this).data('confirm-title'),
            text: $(this).data('confirm-text'),
            icon: "warning",
            buttons: [$(this).data('confirm-cancel'), $(this).data('confirm-accept')],
            dangerMode: true
        }).then(function (willDelete) {
            if(willDelete){
                return createAjaxRequest(self);
            }
        })

    });
	//Loader
	$(function preloaderLoad() {
        if($('.preloader').length){
            $('.preloader').delay(200).fadeOut(300);
        }
        $(".preloader_disabler").on('click', function() {
            $("#preloader").hide();
        });
    });

	// Script Navigation
	! function(n, e, i, a) {
		n.navigation = function(t, s) {
			var o = {
					responsive: !0,
					mobileBreakpoint:992,
					showDuration: 300,
					hideDuration: 300,
					showDelayDuration: 0,
					hideDelayDuration: 0,
					submenuTrigger: "hover",
					effect: "fade",
					submenuIndicator: !0,
					hideSubWhenGoOut: !0,
					visibleSubmenusOnMobile: !1,
					fixed: !1,
					overlay: !0,
					overlayColor: "rgba(0, 0, 0, 0.5)",
					hidden: !1,
					offCanvasSide: "left",
					onInit: function() {},
					onShowOffCanvas: function() {},
					onHideOffCanvas: function() {}
				},
				u = this,
				r = Number.MAX_VALUE,
				d = 1,
				f = "click.nav touchstart.nav",
				l = "mouseenter.nav",
				c = "mouseleave.nav";
			u.settings = {};
			var t = (n(t), t);
			n(t).find(".nav-menus-wrapper").prepend("<span class='nav-menus-wrapper-close-button'>✕</span>"), n(t).find(".nav-search").length > 0 && n(t).find(".nav-search").find("form").prepend("<span class='nav-search-close-button'>✕</span>"), u.init = function() {
				u.settings = n.extend({}, o, s), "right" == u.settings.offCanvasSide && n(t).find(".nav-menus-wrapper").addClass("nav-menus-wrapper-right"), u.settings.hidden && (n(t).addClass("navigation-hidden"), u.settings.mobileBreakpoint = 99999), v(), u.settings.fixed && n(t).addClass("navigation-fixed"), n(t).find(".nav-toggle").on("click touchstart", function(n) {
					n.stopPropagation(), n.preventDefault(), u.showOffcanvas(), s !== a && u.callback("onShowOffCanvas")
				}), n(t).find(".nav-menus-wrapper-close-button").on("click touchstart", function() {
					u.hideOffcanvas(), s !== a && u.callback("onHideOffCanvas")
				}), n(t).find(".nav-search-button").on("click touchstart", function(n) {
					n.stopPropagation(), n.preventDefault(), u.toggleSearch()
				}), n(t).find(".nav-search-close-button").on("click touchstart", function() {
					u.toggleSearch()
				}), n(t).find(".megamenu-tabs").length > 0 && y(), n(e).resize(function() {
					m(), C()
				}), m(), s !== a && u.callback("onInit")
			};
			var v = function() {
				n(t).find("li").each(function() {
					n(this).children(".nav-dropdown,.megamenu-panel").length > 0 && (n(this).children(".nav-dropdown,.megamenu-panel").addClass("nav-submenu"), u.settings.submenuIndicator && n(this).children("a").append("<span class='submenu-indicator'><span class='submenu-indicator-chevron'></span></span>"))
				})
			};
			u.showSubmenu = function(e, i) {
				g() > u.settings.mobileBreakpoint && n(t).find(".nav-search").find("form").slideUp(), "fade" == i ? n(e).children(".nav-submenu").stop(!0, !0).delay(u.settings.showDelayDuration).fadeIn(u.settings.showDuration) : n(e).children(".nav-submenu").stop(!0, !0).delay(u.settings.showDelayDuration).slideDown(u.settings.showDuration), n(e).addClass("nav-submenu-open")
			}, u.hideSubmenu = function(e, i) {
				"fade" == i ? n(e).find(".nav-submenu").stop(!0, !0).delay(u.settings.hideDelayDuration).fadeOut(u.settings.hideDuration) : n(e).find(".nav-submenu").stop(!0, !0).delay(u.settings.hideDelayDuration).slideUp(u.settings.hideDuration), n(e).removeClass("nav-submenu-open").find(".nav-submenu-open").removeClass("nav-submenu-open")
			};
			var h = function() {
					n("body").addClass("no-scroll"), u.settings.overlay && (n(t).append("<div class='nav-overlay-panel'></div>"), n(t).find(".nav-overlay-panel").css("background-color", u.settings.overlayColor).fadeIn(300).on("click touchstart", function(n) {
						u.hideOffcanvas()
					}))
				},
				p = function() {
					n("body").removeClass("no-scroll"), u.settings.overlay && n(t).find(".nav-overlay-panel").fadeOut(400, function() {
						n(this).remove()
					})
				};
			u.showOffcanvas = function() {
				h(), "left" == u.settings.offCanvasSide ? n(t).find(".nav-menus-wrapper").css("transition-property", "left").addClass("nav-menus-wrapper-open") : n(t).find(".nav-menus-wrapper").css("transition-property", "right").addClass("nav-menus-wrapper-open")
			}, u.hideOffcanvas = function() {
				n(t).find(".nav-menus-wrapper").removeClass("nav-menus-wrapper-open").on("webkitTransitionEnd moztransitionend transitionend oTransitionEnd", function() {
					n(t).find(".nav-menus-wrapper").css("transition-property", "none").off()
				}), p()
			}, u.toggleOffcanvas = function() {
				g() <= u.settings.mobileBreakpoint && (n(t).find(".nav-menus-wrapper").hasClass("nav-menus-wrapper-open") ? (u.hideOffcanvas(), s !== a && u.callback("onHideOffCanvas")) : (u.showOffcanvas(), s !== a && u.callback("onShowOffCanvas")))
			}, u.toggleSearch = function() {
				"none" == n(t).find(".nav-search").find("form").css("display") ? (n(t).find(".nav-search").find("form").slideDown(), n(t).find(".nav-submenu").fadeOut(200)) : n(t).find(".nav-search").find("form").slideUp()
			};
			var m = function() {
					u.settings.responsive ? (g() <= u.settings.mobileBreakpoint && r > u.settings.mobileBreakpoint && (n(t).addClass("navigation-portrait").removeClass("navigation-landscape"), D()), g() > u.settings.mobileBreakpoint && d <= u.settings.mobileBreakpoint && (n(t).addClass("navigation-landscape").removeClass("navigation-portrait"), k(), p(), u.hideOffcanvas()), r = g(), d = g()) : k()
				},
				b = function() {
					n("body").on("click.body touchstart.body", function(e) {
						0 === n(e.target).closest(".navigation").length && (n(t).find(".nav-submenu").fadeOut(), n(t).find(".nav-submenu-open").removeClass("nav-submenu-open"), n(t).find(".nav-search").find("form").slideUp())
					})
				},
				g = function() {
					return e.innerWidth || i.documentElement.clientWidth || i.body.clientWidth
				},
				w = function() {
					n(t).find(".nav-menu").find("li, a").off(f).off(l).off(c)
				},
				C = function() {
					if (g() > u.settings.mobileBreakpoint) {
						var e = n(t).outerWidth(!0);
						n(t).find(".nav-menu").children("li").children(".nav-submenu").each(function() {
							n(this).parent().position().left + n(this).outerWidth() > e ? n(this).css("right", 0) : n(this).css("right", "auto")
						})
					}
				},
				y = function() {
					function e(e) {
						var i = n(e).children(".megamenu-tabs-nav").children("li"),
							a = n(e).children(".megamenu-tabs-pane");
						n(i).on("click.tabs touchstart.tabs", function(e) {
							e.stopPropagation(), e.preventDefault(), n(i).removeClass("active"), n(this).addClass("active"), n(a).hide(0).removeClass("active"), n(a[n(this).index()]).show(0).addClass("active")
						})
					}
					if (n(t).find(".megamenu-tabs").length > 0)
						for (var i = n(t).find(".megamenu-tabs"), a = 0; a < i.length; a++) e(i[a])
				},
				k = function() {
					w(), n(t).find(".nav-submenu").hide(0), navigator.userAgent.match(/Mobi/i) || navigator.maxTouchPoints > 0 || "click" == u.settings.submenuTrigger ? n(t).find(".nav-menu, .nav-dropdown").children("li").children("a").on(f, function(i) {
						if (u.hideSubmenu(n(this).parent("li").siblings("li"), u.settings.effect), n(this).closest(".nav-menu").siblings(".nav-menu").find(".nav-submenu").fadeOut(u.settings.hideDuration), n(this).siblings(".nav-submenu").length > 0) {
							if (i.stopPropagation(), i.preventDefault(), "none" == n(this).siblings(".nav-submenu").css("display")) return u.showSubmenu(n(this).parent("li"), u.settings.effect), C(), !1;
							if (u.hideSubmenu(n(this).parent("li"), u.settings.effect), "_blank" == n(this).attr("target") || "blank" == n(this).attr("target")) e.open(n(this).attr("href"));
							else {
								if ("#" == n(this).attr("href") || "" == n(this).attr("href")) return !1;
								e.location.href = n(this).attr("href")
							}
						}
					}) : n(t).find(".nav-menu").find("li").on(l, function() {
						u.showSubmenu(this, u.settings.effect), C()
					}).on(c, function() {
						u.hideSubmenu(this, u.settings.effect)
					}), u.settings.hideSubWhenGoOut && b()
				},
				D = function() {
					w(), n(t).find(".nav-submenu").hide(0), u.settings.visibleSubmenusOnMobile ? n(t).find(".nav-submenu").show(0) : (n(t).find(".nav-submenu").hide(0), n(t).find(".submenu-indicator").removeClass("submenu-indicator-up"), u.settings.submenuIndicator ? n(t).find(".submenu-indicator").on(f, function(e) {
						return e.stopPropagation(), e.preventDefault(), u.hideSubmenu(n(this).parent("a").parent("li").siblings("li"), "slide"), u.hideSubmenu(n(this).closest(".nav-menu").siblings(".nav-menu").children("li"), "slide"), "none" == n(this).parent("a").siblings(".nav-submenu").css("display") ? (n(this).addClass("submenu-indicator-up"), n(this).parent("a").parent("li").siblings("li").find(".submenu-indicator").removeClass("submenu-indicator-up"), n(this).closest(".nav-menu").siblings(".nav-menu").find(".submenu-indicator").removeClass("submenu-indicator-up"), u.showSubmenu(n(this).parent("a").parent("li"), "slide"), !1) : (n(this).parent("a").parent("li").find(".submenu-indicator").removeClass("submenu-indicator-up"), void u.hideSubmenu(n(this).parent("a").parent("li"), "slide"))
					}) : k())
				};
			u.callback = function(n) {
				s[n] !== a && s[n].call(t)
			}, u.init()
		}, n.fn.navigation = function(e) {
			return this.each(function() {
				if (a === n(this).data("navigation")) {
					var i = new n.navigation(this, e);
					n(this).data("navigation", i)
				}
			})
		}
	}
	(jQuery, window, document), $(document).ready(function() {
		$("#navigation").navigation()
	});


	// Script Show Calling Number
	$('#number').on('click', function() {
		var tel = $(this).data('last');
		$(this).find('span').html( '<a href="tel:' + tel + '">' + tel + '</a>' );
	});

	// Metis Menu
	$('#side-menu').metisMenu();

	// Tooltip
	$('[data-toggle="tooltip"]').tooltip();

	// Bottom To Top Scroll Script
	$(window).on('scroll', function() {
		var height = $(window).scrollTop();
		if (height > 100) {
			$('#back2Top').fadeIn();
		} else {
			$('#back2Top').fadeOut();
		}
	});


	// Script For Fix Header on Scroll
	$(window).on('scroll', function() {
		var scroll = $(window).scrollTop();

		if (scroll >= 50) {
			$(".header").addClass("header-fixed");
		} else {
			$(".header").removeClass("header-fixed");
		}
	});

	// Property Slide
	$('.reviews-slide').slick({
	  slidesToShow:3,
	  arrows: true,
	  dots: false,
	  infinite: true,
	  autoplaySpeed: 2000,
	  autoplay:true,
	  responsive: [
		{
		  breakpoint: 1024,
		  settings: {
			arrows: true,
			dots: false,
			slidesToShow:2
		  }
		},
		{
		  breakpoint: 600,
		  settings: {
			arrows: true,
			dots: false,
			slidesToShow:1
		  }
		}
	  ]
	});

	// location Slide
	$('.slide_items').slick({
	  slidesToShow:3,
	  arrows: true,
	  dots: true,
	  infinite: true,
	  speed: 500,
	  cssEase: 'linear',
	  autoplaySpeed: 2000,
	  autoplay:true,
	  responsive: [
		{
		  breakpoint: 1024,
		  settings: {
			arrows: true,
			dots: true,
			slidesToShow:2
		  }
		},
		{
		  breakpoint: 600,
		  settings: {
			arrows: true,
			dots: true,
			slidesToShow:1
		  }
		}
	  ]
	});

	// location Slide
	$('.tutor-slide').slick({
	  slidesToShow:3,
	  arrows: true,
	  dots: true,
	  infinite: true,
	  speed: 500,
	  cssEase: 'linear',
	  autoplaySpeed: 2000,
	  autoplay:true,
	  responsive: [
		{
		  breakpoint: 1024,
		  settings: {
			arrows: true,
			dots: true,
			slidesToShow:2
		  }
		},
		{
		  breakpoint: 600,
		  settings: {
			arrows: true,
			dots: true,
			slidesToShow:1
		  }
		}
	  ]
	});

	// Property Slide
	$('.testi-slide').slick({
	  slidesToShow:3,
	  arrows: false,
	  autoplay:true,
	  responsive: [
		{
		  breakpoint: 1023,
		  settings: {
			arrows: false,
			slidesToShow:2
		  }
		},
		{
		  breakpoint: 768,
		  settings: {
			arrows: false,
			slidesToShow:2
		  }
		},
		{
		  breakpoint: 480,
		  settings: {
			arrows: false,
			slidesToShow:1
		  }
		}
	  ]
	});

	// Summernote
	$('.summernote').summernote();

	// Home Slider
	$('.home-slider').slick({
	  centerMode:false,
	  slidesToShow:1,
	  responsive: [
		{
		  breakpoint: 768,
		  settings: {
			arrows:true,
			slidesToShow:1
		  }
		},
		{
		  breakpoint: 480,
		  settings: {
			arrows: false,
			slidesToShow:1
		  }
		}
	  ]
	});


});
$(document).on('submit', '.frmLogin', function(e) {
    e.preventDefault();
    $('.frmLogin .log-submit-btn').prop('disabled', true);
    $('.msg-failed').hide();
    $('input+small').text('');
    let redirect = ($('.frmLogin .redirect_url').length > 0)?$('.frmLogin .redirect_url').val():"";
    $('input').parent().removeClass('is-invalid');
    $.ajax({
        method: $(this).attr('method'),
        url: $(this).attr('action'),
        data: $(this).serialize(),
        dataType: "json"
    })
        .done(function(response) {
            $('.msg-failed').hide();
            if(redirect === "")
                redirect = response.data;
            window.location.href = redirect;
        })
        .fail(function(data) {
            $('.login-error').show();
            // grecaptcha.reset();
            // if(data.responseJSON.hasOwnProperty('errors') && data.responseJSON.errors.hasOwnProperty('g-recaptcha-response')){
            //     $('.google-error').html(data.responseJSON.errors['g-recaptcha-response'][0]);
            //     $('.login-error').hide();
            //     $('.google-error').show();
            // }else{
            //     $('.google-error').hide();
            //
            // }
            $('.frmLogin .log-submit-btn').prop('disabled', false);

        });
});
function createAjaxRequest(self){
    let loading = self.data('loading');
    let nowLabel = self.text();
    if(loading){
        let loader = '<i class="fal fa-spinner loading-icon disable-click"></i>';
        if(self.html() === loader) return;
        self.html(loader);
    }
    var formData = new FormData();
    let params = (self.data('params'))?self.data('params').split(","):[];
    params.map((field) => {
        formData.set(field, $('#'+field).val());
    })
    let callback = self.data('callback');
    $.ajax({
        method: "POST",
        url: self.data('action'),
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: "json"
    })
        .done(function(response) {
            if(loading){
                self.text(nowLabel);
            }
            if(callback){
                var c = eval(callback)
                if (typeof c == 'function') {
                    c(response)
                }
            }
        })
        .fail(function(data) {
            swal({
                title: lang.error,
                text: data.responseJSON.data,
                button: lang.close,
                icon: "error", //built in icons: success, warning, error, info
                timer: 3000,
            });
            if(loading){
                self.text(nowLabel);
            }
        });
}
function ajaxRequest(input){
    var formData = new FormData();
    let params = ($(input).data('params'))?$(input).data('params').split(","):[];
    if($(input).data('method') === "POST")
        params.map((field) => {
            formData.set(field, $('#'+field).val());
        })
    let callback = $(input).data('callback');
    $.ajax({
        method: $(input).data('method'),
        url: $(input).data('action'),
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: "json"
    })
        .done(function(response) {
            if(callback){
                var c = eval(callback)
                if (typeof c == 'function') {
                    c(response)
                }
            }
        })
        .fail(function(data) {
            console.log(data);
            swal({
                title: lang.error,
                text: data.responseJSON.data,
                button: lang.close,
                icon: "error", //built in icons: success, warning, error, info
                timer: 3000,
            });

        });
}
$(document).on('change', '.ajax-request', function(e){
    if(e.target.type === "select-one")
        ajaxRequest($(this));
});
window.fillSelect = function(element, data, key, value){
    let html = [];
    html.push('<option value="">'+lang.pleaseChoose+'</option>')
    data.map((row, index) => {
        html.push('<option value="'+row[key]+'">'+row[value]+'</option>')
    });
    $('#' + element).html(html.join(''));
}
$(document).on('click', '.btn-if-auth', function(e){
    if($(this).data('auth') === "off"){
        e.preventDefault();
        $('#'+$(this).data('modal')).modal('show');
        $('#'+$(this).data('modal')+' .redirect_url').val($(this).attr('href'));
    }
});
$(document).on('click', '.btn-favorite', function(e){
    if($(this).data('auth') === "off") return false;
    var formData = new FormData();
    let field = ($(this).data('field'))?$(this).data('field'):'activity_id';
    let id = $(this).data('id');
    formData.set(field, id);
    let link = $(this);
    let callback = $(this).data('callback');

    $.ajax({
        method: $(this).data('method'),
        url: $(this).data('action'),
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: "json"
    })
        .done(function(response) {
            if(callback){
                var c = eval(callback)
                if (typeof c == 'function') {
                    c(id)
                }
            }else{
                if($(link).find('i').length > 0){
                    let icon = $(link).find('i')[0];
                    if($(icon).hasClass('far'))
                        $(icon).removeClass('far').addClass('fa');
                    else
                        $(icon).removeClass('fa').addClass('far');

                    if($(icon).hasClass('active'))
                        $(icon).removeClass('active');
                    else
                        $(icon).addClass('active');

                }
            }


        })
        .fail(function(data) {
            console.log(data);
            swal({
                title: lang.error,
                text: data.responseJSON.data,
                button: lang.close,
                icon: "error", //built in icons: success, warning, error, info
                timer: 3000,
            });

        });
});
(function( $ ){
    let errors = [];
    $.fn.requiredDecider = function(element, operator, value) {
        if(value === "null") value = null;
        if(value === "empty") value = "";
        switch (operator){
            case "!=": return (element !== value)
            case "=": return (element === value)
            case ">": return (element > value)
            case "<": return (element < value)
            case ">=": return (element >= value)
            case "<=": return (element <= value)
        }

        return false;
    }
    $.fn.checkValidity = function(options, key) {
        let rule = options[key];

        $(this).parent().removeClass('is-invalid');
        $(this).parent().find('small').remove();
        if(rule.hasOwnProperty("format") && rule.format){
            let old = $(this);
            $(old).on('change', function (e){
                $(old).checkValidity(options, key);
            });
            if(rule.format.hasOwnProperty("format")){
                let format = rule.format.format;
                if(format === "phone"){
                    if(!validatePhone($(this).val())){
                        $(this).parent().addClass('is-invalid');
                        $(this).parent().append('<small>'+rule.format.message+'</small>');
                        return false;
                    }
                }
            }
        }
        if(rule.hasOwnProperty("required") && rule.required){
            let old = $(this);
            $(old).on('change', function (e){
                $(old).checkValidity(options, key);
            })
            if($(this).val() === ""){
                $(this).parent().addClass('is-invalid');
                $(this).parent().append('<small>'+rule.required.message+'</small>');
                return false;
            }
            if(rule.required.hasOwnProperty("format")){
                let format = rule.required.format;
                if(format === "email"){
                    if(!validateEmail($(this).val())){
                        $(this).parent().addClass('is-invalid');
                        $(this).parent().append('<small>'+rule.required.format_message+'</small>');
                        return false;
                    }
                }
                if(format === "password"){
                    if(!validatePassword($(this).val())){
                        $(this).parent().addClass('is-invalid');
                        $(this).parent().append('<small>'+rule.required.format_message+'</small>');
                        return false;
                    }
                }
                if(format === "phone"){
                    if(!validatePhone($(this).val())){
                        $(this).parent().addClass('is-invalid');
                        $(this).parent().append('<small>'+rule.required.format_message+'</small>');
                        return false;
                    }
                }
                if(format === "identity"){
                    if(!checkTcNum($(this).val())){
                        $(this).parent().addClass('is-invalid');
                        $(this).parent().append('<small>'+rule.required.format_message+'</small>');
                        return false;
                    }
                }

            }
        }
        if(rule.hasOwnProperty("required_if")){
            let condition = rule.required_if.rule.split(",");
            let element = $('#'+condition[0]);
            let required_checked = false;
            if($(element).attr('type') === "checkbox"){
                let checked = (condition[1] === "checked");
                if($(element).prop("checked") === checked) required_checked = true;
            }else{
                if(condition.length === 3 && $(this).requiredDecider($(element).val(), condition[1], condition[2])) required_checked = true;
            }
            if(required_checked){
                let old = $(this);
                $(element).on('change', function (e){
                    $(old).checkValidity(options, key);
                })
                if((this).val() === ""){
                    $(this).parent().addClass('is-invalid');
                    $(this).parent().append('<small>'+rule.required_if.message+'</small>');
                    return false;
                }
                if(rule.required_if.hasOwnProperty("format")){
                    let format = rule.required_if.format;
                    if(format === "email"){
                        if(!validateEmail($(this).val())){
                            $(this).parent().addClass('is-invalid');
                            $(this).parent().append('<small>'+rule.required_if.format_message+'</small>');
                            return false;
                        }
                    }
                }
            }

        }
        if(rule.hasOwnProperty("format_if_not_null")){
            if(rule.format_if_not_null.hasOwnProperty("format")) {
                let format = rule.format_if_not_null.format;
                if(format === "identity"){
                    if($(this).val() !== "" && !checkTcNum($(this).val())){
                        $(this).parent().addClass('is-invalid');
                        $(this).parent().append('<small>'+rule.format_if_not_null.message+'</small>');
                        return false;
                    }
                }
            }
        }

        return true;
    };
    $.fn.validate = function(options, callback = null) {
        Object.keys(options).map((key, index) => {
            let input = $('#'+key);
            if($('#'+key).length > 0){
                if($(input).attr('type') === "text" || $(input).attr('type') === "password"){
                    $(input).on('keyup', function (e){
                        $(this).checkValidity(options, key);
                    })
                }
                if(!$(input).checkValidity(options, key)){
                    if(!errors.includes(key))
                        errors.push(key);
                }
                else{
                    errors.map((r, ind) => r === key && errors.splice(ind, 1))
                }
            }else{
                errors.map((r, ind) => r === key && errors.splice(ind, 1))
            }
        });
        if(callback) callback(errors);
        return (errors.length === 0);
    };
})( jQuery );
